@keyframes fade-in-out {
  0% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.fadeInOut {
  animation: fade-in-out 3s ease-in-out;
}
