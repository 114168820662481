#video {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  background-color: #ffffff;

  #left {
    flex-grow: 1;
    margin-right: 19px;
    margin-left: 16px;
    max-width: 400px;
    min-width: 400px;
  }

  #right {
    margin-right: 45px;
    flex-grow: 5;

    /* Neutral/White */
    background: #ffffff;
    /* Grey / Grey 4 */
    border: 1px solid #f0ecee;
    box-sizing: border-box;
    border-radius: 8px;
  }
}

#controls {
  margin-top: 50px;
  padding-left: 16px;
  padding-right: 45px;
  /* Neutral/White */
  // background: #FFFFFF;
}
