.slidebar-tabs.ant-tabs-top {
  height: 100%;

  .ant-tabs-nav,
  .ant-tabs-tab {
    margin: 0;
    display: none !important;
  }

  .ant-tabs-nav-list,
  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-nav-list {
    background: #070550;
    justify-content: center;
    border-radius: 8px;
    height: 58px;
    display: none !important;
    & > .ant-tabs-tab {
      flex: 1 1 auto;

      .tab-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .icon {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;

          .file-ico {
            filter: invert(1);
          }
        }

        .title {
          margin-top: 2px;

          font-size: 10px;
          line-height: 12px;
          /* identical to box height, or 120% */
          /* Neutral/White */
          color: #ffffff;
        }

        .pointer {
          display: none;
          position: absolute;
          top: calc(50% - 8px / 2 + 20px);
        }
      }

      &.ant-tabs-tab-active,
      &:hover {
        .icon {
          background: #91dbb6;

          // &.file-ico {
          //     filter: invert(7%) sepia(46%) saturate(6657%) hue-rotate(238deg) brightness(101%) contrast(146%);
          // }
        }
      }
    }
  }

  .ant-tabs-content-holder {
    // margin-top: 16px;
    height: 100%;
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
      .file-tab {
        height: 100%;
      }
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}
