/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Objective';
  font-weight: 400;
  overflow-y: hidden;
}

body::-webkit-scrollbar,
div::-webkit-scrollbar,
textarea::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background-color: rgba(7, 5, 80, 0.1);
  border: 3px solid #fff;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-clip: padding-box;
  border: 3px solid rgba(0, 0, 0, 0);
  background-color: #b5adb0;
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-Thin.otf') format('opentype');
  font-display: swap;
  font-weight: 100 200;
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-Light.otf') format('opentype');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: 400 500;
  /* 500 should be medium */
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-Medium.otf') format('opentype');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-Bold.otf') format('opentype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Objective';
  src: url('../public/assets/fonts/Objective/Objective-ExtraBold.otf') format('opentype');
  font-display: swap;
  font-weight: 800 900;
}

@font-face {
  font-family: 'Objective Bold';
  src: url('../public/assets/fonts/Objective/Objective-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Nova Oval';
  src: url('../public/assets/fonts/NovaOval/NovaOval-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Mystery Quest';
  src: url('../public/assets/fonts/MysteryQuest/MysteryQuest-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Noto Serif Gujarati';
  src: url('../public/assets/fonts/NotoSerifGujarati/NotoSerifGujarati-VariableFont_wght.ttf');
}

body::-webkit-scrollbar,
div::-webkit-scrollbar,
textarea::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background-color: rgba(7, 5, 80, 0.1);
  border: 3px solid #fff;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-clip: padding-box;
  border: 3px solid rgba(0, 0, 0, 0);
  background-color: #b5adb0;
}

.initialization-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker .ant-picker-input {
  flex-direction: row-reverse;
}

.video-react .video-react-big-play-button {
  display: none !important;
}
