.loading-icon {
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        animation: rotation 1s infinite linear;

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}