@import '~antd/dist/antd.css';
body {
  #main {
    flex-direction: row;
    min-height: 100vh;
    background: #faf7f8;
  }
  .ant-picker.time-picker.ant-picker-disabled {
    .ant-picker-input input {
      color: #070632 !important;
      font-size: 13px;
    }
  }
}
