.fadein {
  visibility: visible;
  opacity: 0;
  transition-delay: 0.5s;
  z-index: 1;
  animation: fade-in 1.5s ease-in both;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.flyin {
  visibility: visible;
  opacity: 0;
  transition-delay: 0.5s;
  z-index: 1;
  animation: fly-in 1.5s ease-in both;

  @keyframes fly-in {
    from {
      opacity: 0.4;
      transform: translate3d(0, -40%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.wipein {
  visibility: visible;
  opacity: 0;
  transition-delay: 0.5s;
  z-index: 1;
  animation: wipe-in 1.5s ease-in both;

  @keyframes wipe-in {
    from {
      opacity: 0.4;
      transform: translate3d(-20%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.zoomin {
  visibility: visible;
  transition-delay: 0.5s;
  animation: zoom-in 1.5s ease-in both;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
