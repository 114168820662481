.header {
  height: 96px;
  /* Grey / Grey 1 */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-text {
    font-family: 'Objective';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */
    letter-spacing: -1px;
    /* Grey / Grey 7 */
    color: #070550;
    margin-top: 31px;
    margin-bottom: 25px;
  }
  box-shadow: 0px 4px 12px rgba(41, 43, 50, 0.08);
}
