@import '~video-react/styles/scss/video-react';
.sound-slider {
  width: 72px;

  .ant-slider-rail {
    height: 2px;
    /* Grey / Grey 3 */
    background: #f6f3f4;
    border-radius: 12px;
  }

  .ant-slider-track {
    background: #91dbb6;
    border-radius: 12px;
    height: 2px;
  }
  .ant-slider-handle {
    background: #91dbb6;
  }
}
